<template>
  <section>
    <div class="content-header">
      <h2>Understanding the realities and needs of Indigenous learners</h2>
    </div>
    <div class="content-wrapper">
      <p>Dr. Chantelle Richmond and Dr. Candace Brunette-Debassige from Western University have developed a <a href="https://teaching.uwo.ca/pdf/teaching/Guide-for-Working-with-Indigenous-Students.pdf" target="_blank">Guide for Working with Indigenous Students</a>,  a rich resource for faculty and staff.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
